/* eslint-disable react/display-name */
import React from 'react';
import styled, { css } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { Card } from 'src/components/atoms/Card';
import { List, ListItem } from 'src/components/atoms/List';
import { Icon } from 'src/components/atoms/Icon';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { FluidImg, FixedImg } from 'src/embeds/image/image-dato';

function createColumns(n) {
  return Array(n).fill('1fr').join(' ');
}

const CalloutHeader = styled.h3`
  min-height: 3rem;
  ${({ customTextColor }) => customTextColor && css`
    color: ${customTextColor}
  `}
`

export default function Drawer({
  heading,
  content,
  contentNode,
  callouts,
  ctas,
  customData,
  backgroundImage,
}) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  const drawerCustomData = JSON.parse(customData);
  return (
    <Section
      {...drawerCustomData?.flags}
      backgroundImage={
        !!backgroundImage && (
          <FluidImg pictureStyle={{ objectFit: 'cover' }} maxWidth={null} data={backgroundImage} />
        )
      }
    >
      <SectionHeader>
        {heading &&<h2>{heading}</h2>}
        {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      </SectionHeader>
      {callouts.length > 0 ? (
        <Grid columns={createColumns(drawerCustomData?.columnCount ?? callouts.length)}>
          {callouts.map(({ heading, customData, contentNode, ctas, media }, i) => {
            customData = customData ? JSON.parse(customData) : { secondary: true };
            const cardFlags = drawerCustomData?.cardFlags || {}
            return (
              <Card {...cardFlags} key={i}>
                {media && <FixedImg
                  data={media}
                  style={cardFlags.imageStyles || {}}
                  width={cardFlags.imageStyles?.width || media.width} />
                }
                <div>
                  {heading
                    && cardFlags.alignLeft
                    ? <CalloutHeader {...customData}>{heading}</CalloutHeader>
                    : <center><CalloutHeader {...customData}>{heading}</CalloutHeader></center>
                  }
                  <MDXProvider
                    components={{
                      ul: List,
                      li: ({ children }) => {
                        if (cardFlags.absentListItemIcon) {
                          return (
                            <ListItem left="0rem">
                              {children}
                            </ListItem>
                          )
                        }
                        return (
                          <ListItem icon={<Icon icon="check-circle" />}>
                            {children}
                          </ListItem>
                        )
                      },
                    }}
                  >
                    <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
                  </MDXProvider>
                  {ctas.length > 0 && (
                    <InlineList centered style={{ display: 'flex' }}>
                      {ctas.map((cta, i) => {
                        const ctaFlags = i > 0 ? { secondary: true } : {};
                        return (
                          <li key={i}>
                            <Button to={cta.url} {...ctaFlags}>
                              {cta.text}
                            </Button>
                          </li>
                        );
                      })}
                    </InlineList>
                  )}
                </div>
              </Card>
            );
          })}
        </Grid>
      ) : null}
      {ctas.length > 0 && (
        <InlineList centered style={{ width: '100%' }}>
          {ctas.map((cta, i) => {
            const ctaFlags = i > 0 ? { secondary: true } : {};
            return (
              <li key={i}>
                <Button to={cta.url} {...ctaFlags}>
                  {cta.text}
                </Button>
              </li>
            );
          })}
        </InlineList>
      )}
    </Section>
  );
}
